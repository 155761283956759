import { appAPI } from './apiConn';

export interface Guest {
  name: string;
  role: string;
  attending: boolean;
}

export interface Invitation {
  id: string;
  guests: Guest[];
  plus_one_allowed: boolean;
  plus_one_added: boolean;
}

export const GetInvitationByShortCode = (
  shortCode: string,
  onSuccess: (invitation: Invitation) => void,
  onError: (error: string) => void
) => {
  appAPI
    .get(`/invite/${shortCode}`)
    .then((res) => {
      let invitation: Invitation = res.data;
      onSuccess(invitation);
    })
    .catch((err) => {
      onError('API error');
    });
};

export const UpdateInvitation = (
  invitation: Invitation,
  onSuccess: (invitation: Invitation) => void,
  onError: (error: string) => void
) => {
  appAPI
    .post(`/invite/${invitation.id}`, invitation)
    .then((res) => {
      let rtnInvitation: Invitation = res.data;
      onSuccess(rtnInvitation);
    })
    .catch((err) => {
      onError('API error');
    });
};
