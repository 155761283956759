import { useState } from 'react';
import { Guest } from '../Application/invitation';
import styles from './GuestSelector.module.scss';

type GuestSelectorProps = {
  index: number;
  guest: Guest;
  enabled: boolean;
  onGuestSelected: (index: number, attending: boolean) => void;
};

const GuestSelector: React.FC<GuestSelectorProps> = (
  props: GuestSelectorProps
) => {
  // Guest Selector
  const [guest, setGuest] = useState<Guest>(props.guest);

  const [yesClass, setYesClass] = useState<string>(
    props.guest.attending === true ? styles.YesButtonSelected : styles.YesButton
  );
  const [noClass, setNoClass] = useState<string>(
    props.guest.attending === false ? styles.NoButtonSelected : styles.NoButton
  );
  const [enabled, setEnabled] = useState<boolean>(props.enabled);

  // Render
  return (
    <div className={styles.GuestSelector}>
      <div className={styles.GuestSelectorNameRole}>
        <p className={styles.Name}>{guest.name}</p>
        <p className={styles.Role}>{guest.role}</p>
      </div>
      <div className={styles.AttendingBox}>
        <button
          className={yesClass}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            props.onGuestSelected(props.index, true);
            setGuest(props.guest);
            setYesClass(styles.YesButtonSelected);
            setNoClass(styles.NoButton);
          }}
          disabled={!enabled}
        >
          YES
        </button>
        <button
          className={noClass}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            props.onGuestSelected(props.index, false);
            setGuest(props.guest);
            setYesClass(styles.YesButton);
            setNoClass(styles.NoButtonSelected);
          }}
          disabled={!enabled}
        >
          NO
        </button>
      </div>
    </div>
  );
};

export default GuestSelector;
