import '@fontsource-variable/dancing-script';
import '@fontsource/overlock';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  GetInvitationByShortCode,
  Invitation,
  UpdateInvitation,
} from '../Application/invitation';
import GuestSelector from './GuestSelector';
import styles from './PageLayout.module.scss';

const PageLayout: React.FC = () => {
  // Get the shortcode from the URL
  let { shortcode } = useParams();

  // Page Tab Accessor
  const [tab, setTab] = useState(1);

  // Invitation Accessor
  const [invitation, setInvitation] = useState<Invitation | undefined>();
  const [plusOneName, setPlusOneName] = useState<string>('');
  const [inputEnabled, setInputEnabled] = useState<boolean>(true);

  // Saved Message
  const [savedMessage, setSavedMessage] = useState<string | undefined>(
    undefined
  );

  // Form Handler
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (invitation) {
      setInputEnabled(false);
      if (
        invitation?.plus_one_allowed &&
        invitation.plus_one_added !== true &&
        plusOneName !== ''
      ) {
        invitation.guests.push({
          attending: true,
          name: plusOneName,
          role: 'Plus One',
        });
        invitation.plus_one_added = true;
      }
      UpdateInvitation(
        invitation,
        (i) => {
          setInvitation(i);
          setInputEnabled(true);
          setSavedMessage('Your RSVP has been saved');
          // Clear the saved message after 5 seconds
          setTimeout(() => {
            setSavedMessage(undefined);
          }, 2500);
        },
        (e) => {}
      );
    }
  };

  // Fetch the invitation
  useEffect(() => {
    if (shortcode && !invitation) {
      GetInvitationByShortCode(
        shortcode,
        (i) => {
          setInvitation(i);
        },
        (e) => {
          console.error(e);
        }
      );
    }
  }, [invitation, shortcode]);
  // Render
  return (
    <main className={styles.Main}>
      <div className={styles.ContentColumn}>
        <div className={styles.PhotoHeader} />
        <div className={styles.InviteHeader}>
          You are cordially invited to celebrate the marriage of
        </div>
        <div className={styles.AndrewAndAra}>Andrew</div>
        <div className={styles.And}>- and -</div>
        <div className={styles.AndrewAndAra}>Ara</div>
        <div className={styles.InviteHeader}>
          On Friday 24<sup>th</sup> May 2024 at 2:30pm
        </div>
        <div className={styles.TabList}>
          <button
            className={tab === 1 ? styles.TabButtonSelected : styles.TabButton}
            onClick={(e) => {
              e.preventDefault();
              setTab(1);
            }}
          >
            RSVP
          </button>
          <button
            className={tab === 2 ? styles.TabButtonSelected : styles.TabButton}
            onClick={(e) => {
              e.preventDefault();
              setTab(2);
            }}
          >
            Dress Code
          </button>
          <button
            className={tab === 3 ? styles.TabButtonSelected : styles.TabButton}
            onClick={(e) => {
              e.preventDefault();
              setTab(3);
            }}
          >
            Directions
          </button>
        </div>
        {tab === 1 && (
          <form className={styles.GuestList} onSubmit={handleSubmit}>
            {savedMessage && (
              <div className={styles.SavedMessage}>{savedMessage}</div>
            )}
            {invitation?.guests.map((g, i) => {
              return (
                <GuestSelector
                  key={'guest-' + i}
                  index={i}
                  guest={g}
                  onGuestSelected={(i, attending) => {
                    if (invitation) {
                      invitation.guests[i].attending = attending;
                      setInvitation(invitation);
                    }
                  }}
                  enabled={inputEnabled}
                />
              );
            })}
            {invitation?.plus_one_allowed &&
              invitation.plus_one_added !== true && (
                <div className={styles.FormControl}>
                  <label htmlFor='plus-one'>Plus One</label>
                  <input
                    id='plus-one'
                    type='text'
                    placeholder='Name'
                    value={plusOneName}
                    onChange={(e) => {
                      setPlusOneName(e.target.value);
                    }}
                    disabled={!inputEnabled}
                    autoComplete='off'
                  />
                </div>
              )}
            <button
              className={styles.SubmitButton}
              type='submit'
              disabled={!inputEnabled}
            >
              Send my RSVP
            </button>
          </form>
        )}
        {tab === 2 && (
          <div className={styles.TabContent}>
            <h2>Dress Code</h2>
            <h4>Attire</h4>
            <p>Semi Formal</p>
            <h4>Men</h4>
            <p>Long Sleeve Shirt, Long Sleeve Polo</p>
            <h4>Women</h4>
            <p>Dress, Coordinated Outfit</p>
            <h4>Colour Scheme</h4>
            <div className={styles.ColourScheme}>
              <div className={styles.ColourPurple} />
              <div className={styles.ColourYellow} />
              <div className={styles.ColourLightGray} />
              <div className={styles.ColourDarkGray} />
              <div className={styles.ColourLightBlue} />
              <div className={styles.ColourDarkBlue} />
            </div>
          </div>
        )}
        {tab === 3 && (
          <div className={styles.TabContent}>
            <h2>Directions</h2>
            <h3>Address</h3>
            <p>
              Casa Astillero
              <br />
              Barangay Sta. Ana Calatagan Batangas,
              <br />
              Calatagan,
              <br />
              Batangas,
              <br />
              Philippines
            </p>
            <iframe
              title='Directions'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30998.981700932574!2d120.62424216212911!3d13.78654589295271!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33bdb0cb8c3c67c5%3A0x12a13ad55462bae5!2sCasa%20Astillero!5e0!3m2!1sen!2suk!4v1704033177042!5m2!1sen!2suk'
              width='100%'
              height='300'
              style={{ border: 0 }}
              allowFullScreen
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
            ></iframe>
          </div>
        )}
      </div>
    </main>
  );
};

export default PageLayout;
