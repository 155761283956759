import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.scss';
import PageLayout from './Components/PageLayout';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/:shortcode' element={<PageLayout />} />
      </Routes>
    </Router>
  );
}

export default App;
